var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBasic)+" ")]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"device-total",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('All Devices'),"options":_vm.deviceTypeList,"searchable":false},on:{"input":_vm.filterDeviceList},model:{value:(_vm.selectedDeviceType),callback:function ($$v) {_vm.selectedDeviceType=$$v},expression:"selectedDeviceType"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',{staticClass:"search-row"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-input-group',{},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Search')},model:{value:(_vm.selectedDevicesSearch),callback:function ($$v) {_vm.selectedDevicesSearch=$$v},expression:"selectedDevicesSearch"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{staticClass:"search-btn",attrs:{"variant":"none"},on:{"click":_vm.filterDeviceList}},[_c('span',[_vm._v(_vm._s(_vm.$t("GO")))])])],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-baseline",attrs:{"cols":"12","md":"8"}})],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.deviceRowsOffline,"rtl":_vm.direction,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: false, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
      perPageDropdown: _vm.pageOptions,
      nextLabel: _vm.$t('Next'),
      prevLabel: _vm.$t('Prev'),
      rowsPerPageLabel: _vm.$t('Rows per page'),
      ofLabel: _vm.$t('of'),
    }},on:{"on-row-click":_vm.openDeviceDetails},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'typology')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(props.row.typology)))])]):(props.column.field === 'example2')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example2))])]):(props.column.field === 'example3')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example3))])]):(props.column.field === 'example5')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example5))])]):(props.column.field === 'example6')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example6))])]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }