<template>
  <div>
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card class="home-general">
          <b-row>
            <b-col lg="6">
              <div class="general-left general-status-home-title">
                <b-row class="general-status-row">
                  <b-col cols="12" xl="6">
                    <span class="text-status"> {{$t("General Status")}} </span>
                  </b-col>
                  <b-col class="align-select-end" cols="12" xl="6">
                    <b-form-group>
                      <v-select
                        class="status-select"
                        :placeholder="$t('Now')"
                        v-model="selectedDateFilter"
                        :options="filterDate"
                        :searchable="false"
                        @input="filter"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <span>
                <feather-icon class="download-icon" icon="DownloadIcon" @click="downloadPDF()"/>
              </span>
              <div class="range-section">
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatus.active_without_alerts"
                    :max="generalStatus.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range"
                    disabled
                  />
                  <span>{{ generalStatus.active_without_alerts }}</span>
                </div>
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatus.active_with_alerts"
                    :max="generalStatus.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range range-2"
                    disabled
                  />
                  <span>{{ generalStatus.active_with_alerts }}</span>
                </div>
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatus.inactive"
                    :max="generalStatus.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range range-3"
                    disabled
                  />
                  <span>{{ generalStatus.inactive }}</span>
                </div>
                <div class="status-sub">
                  <span>
                    <feather-icon class="circle-icon green" icon="CircleIcon" />
                    {{$t("Active Devices")}}
                  </span>
                  <span>
                    <feather-icon class="circle-icon red" icon="CircleIcon" />
                    {{$t("Devices With Alerts")}}
                  </span>
                  <span>
                    <feather-icon class="circle-icon gray" icon="CircleIcon" />
                    {{$t("Offline Devices")}}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="general-left general-status-home-title">
                <b-row class="general-status-row">
                  <b-col cols="12" xl="6">
                    <span class="text-status by-site-mobile"> {{$t("General Status by Site")}} </span>
                  </b-col>
                  <b-col class="align-select-end" cols="12" xl="6">
                    <b-form-group>
                    <v-select
                      class="status-select"
                      :placeholder="$t('All')"
                      v-model="selectedLocationFilter"
                      :options="deviceLocationFilter"
                      :searchable="false"
                      @input="filterLocation"
                    />
                  </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <span>
                <feather-icon class="download-icon" icon="DownloadIcon" @click="downloadPDF(true)"/>
              </span>
              <div class="range-section">
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatusBySite.active_without_alerts"
                    :max="generalStatusBySite.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range"
                    disabled
                  />
                  <span>{{ generalStatusBySite.active_without_alerts }}</span>
                </div>
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatusBySite.active_with_alerts"
                    :max="generalStatusBySite.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range range-2"
                    disabled
                  />
                  <span>{{ generalStatusBySite.active_with_alerts }}</span>
                </div>
                <div class="status-range">
                  <vue-slider
                    v-model="generalStatusBySite.inactive"
                    :max="generalStatusBySite.total"
                    :min="0"
                    :interval="1"
                    direction="btt"
                    class="range range-3"
                    disabled
                  />
                  <span>{{ generalStatusBySite.inactive }}</span>
                </div>
                <div class="status-sub">
                  <span>
                    <feather-icon class="circle-icon green" icon="CircleIcon" />
                   {{$t("Active Devices")}}
                  </span>
                  <span>
                    <feather-icon class="circle-icon red" icon="CircleIcon" />
                    {{$t("Devices With Alerts")}}
                  </span>
                  <span>
                    <feather-icon class="circle-icon gray" icon="CircleIcon" />
                    {{$t("Offline Devices")}}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BRow,
    BCol,
    BLink,
    BImg,
    VueSlider,
    VBTooltip,
    vSelect,
    ToastificationContent
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      filterDate: [
        { id: "Now", label: this.$t("Now") },
        { id: "Week", label: this.$t("Week")},
        { id: "Month", label: this.$t("Month") },
      ],
      selectedDateFilter: null,
      initial_date: "",
      end_date: "",
      location: "",
      selectedLocationFilter: null,
    };
  },
  methods: {
    filter(value) {
      value = value ? value.id : "";
      this.createQueryParams(value);
      this.$store.dispatch("devices/getGeneralStatus", {
        context: this,
        initial_date: this.initial_date,
        end_date: this.end_date,
        home_info: value,
      });
    },
    createQueryParams(filter) {
      let d1 = new Date();
      let date = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
      switch (filter) {
        case "Week":
          this.initial_date = this.getLastWeeksDate(date);
          this.end_date = this.format_date(date);
          break;

        case "Month":
          this.initial_date = this.getLastMonthDate(date);
          this.end_date = this.format_date(date);
          break;

        default:
          this.initial_date = this.getLast15Minutes(date);
          this.end_date = this.format_date(date);
          break;
      }
    },
    getLastWeeksDate(now) {
      let new_date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );
      new_date.setHours(0, 0, 0, 0);
      return this.format_date(new_date);
    },
    getLastMonthDate(now) {
      let new_date = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      new_date.setHours(0, 0, 0, 0);
      return this.format_date(new_date);
    },
    getLast15Minutes(now) {
      let new_date = new Date(now.getTime() - 1000 * 60 * 15);
      return this.format_date(new_date);
    },
    format_date(d) {
      return (
        [d.getFullYear(), d.getMonth() + 1, d.getDate()].join("-") +
        " " +
        [d.getHours(), d.getMinutes(), d.getSeconds()].join(":")
      );
    },
    filterLocation(value) {
      let param = value ? value.id : "";
      this.$store.dispatch("devices/getGeneralStatusBySite", {
        context: this,
        location: param,
      });
    },
    downloadPDF(location=false) {
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Processing Download',
                icon: 'Download',
                variant: 'success',
              },
            });
      if(location){
        this.$store.dispatch("devices/getGeneralStatusBySitePDF", {
          context: this,
          location: this.selectedLocationFilter ? this.selectedLocationFilter.id : "",
        }).then(
          response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'General-status-by-site-report.pdf'
            link.click()
            URL.revokeObjectURL(link.href)
        })
        .catch( error => 
          {
               this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: this.$t('Error'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: error.data
                      },
                    }) 
          });
      }
      else{
        this.$store.dispatch("devices/getGeneralStatusPDF", {
          context: this,
          initial_date: this.initial_date,
          end_date: this.end_date,
        }).then(
          response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'General-status-report.pdf'
            link.click()
            URL.revokeObjectURL(link.href)
        })
        .catch( error => 
          {
               this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: this.$t('Error'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: error.data
                      },
                    }) 
          });
      }
    }

  },
  computed: {
    generalStatus() {
      return this.$store.state.devices.generalStatus;
    },
    deviceLocationFilter() {
      let listLocation = [];
      let devicesList = this.$store.state.devices.devicesList;
      devicesList.forEach((c) => {
        if (c["location"]) {
          listLocation.push({ id: c["location"], label: c["location"] });
        }
      });
      return [
        ...new Map(
          listLocation.map((item) => [JSON.stringify(item), item])
        ).values(),
      ];
    },
    generalStatusBySite() {
      return this.$store.state.devices.generalStatusBySite;
    },
  },
  created() {
    this.createQueryParams();
    this.$store.dispatch("devices/getGeneralStatus", {
      context: this,
      initial_date: this.initial_date,
      end_date: this.end_date,
      home_info: "",
    });
    this.$store.dispatch("devices/getGeneralStatusBySite", {
      context: this,
      location: "",
    });
  },
};
</script>
<style>
.general-status-row{
  width: 100%;
}

.align-select-end{
  display:flex;
  justify-content: end;
}

@media (max-width: 500px) {
  .by-site-mobile{
    max-width: 40%;
  }

  .general-status-home-title{
    height: 70px;
  }

  .align-select-end{
    display:flex;
    justify-content:start;
  }

  .status-select {
    width: 350px;
  }
}
</style>