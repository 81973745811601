<template>
  <b-row>
    <b-col cols="12">
      <!--DEVICES INFO SLIDER-->
      <swiper :options="swiperOptions" ref="swiper_device" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        @mouseenter.native="stopStartSlider('stop')" @mouseleave.native="stopStartSlider('start')">
        <swiper-slide v-for="device in devices" :key="device.id">
          <div class="pb-1" v-if="device.typology == 'Blast Chiller'">
            <b-row class="match-height">
              <b-col md="12" lg="12">
                <b-card no-body class="card-mobile">
                  <b-card-body>
                    <div class="home-top d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                      <b-card-title class="home-title mb-0">{{ device.name }}
                      </b-card-title>
                      <div class="device-info-wrapper relative d-flex align-items-center justify-content-lg-end">
                        <p class="serial-number">
                          {{ $t("Serial") }}:
                          <span>{{ device.serial_number }}</span>
                        </p>
                        <p class="model-number">
                          {{ $t("Model") }}:
                          <span>{{ device.model_code }}</span>
                        </p>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="align-items-center">
              <b-col md="12" lg="4">
                <b-card no-body class="card-mobile">
                  <b-card-body>
                    <div class="home-img home-1">
                      <img :src="`${imageS3}${device.image}`" alt="" style="width: 50%; display: block; margin: 0 auto;"/>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="12" lg="8">
                <b-card no-body class="home-status card-mobile">
                  <b-card-body>
                    <b-row>
                      <b-col class="status-side" lg="3">
                        <b-card-title class="text-status mb-0">{{ $t("Operation") }}
                        </b-card-title>
                        <div class="home-operation">
                          <div class="span-operation">
                            <span style="font-size: 40px">{{ $t(device.operation_status) }}</span>
                          </div>
                          <div class="span-blast-chiller">
                            <span>{{ $t(device.operation_description) }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col class="status-side" lg="3">
                        <b-card-title class="text-status mb-0">{{ $t("Active Alerts") }}
                        </b-card-title>
                        <div class="home-alert">
                          <div style="position: relative">
                            <feather-icon class="alert-icon-2" icon="BellIcon" />
                            <span class="number">{{ device.active_alerts_counter}}</span>
                          </div>
                          <span class="span-blast">{{ $t(device.device_alert) }}</span>
                        </div>
                      </b-col>
                      <b-col class="status-side" lg="3">
                        <b-card-title class="text-status mb-0">{{ $t("Device Status") }}
                        </b-card-title>
                        <div class="status-icons">
                          <div class="icon-status" style="margin-top: 50px; border: none">
                            <img v-if="device.status === 'OK!'" src="@/assets/images/icons/ok.png" alt="" />
                            <img v-else-if="device.status == 'NOK!'" src="@/assets/images/icons/nok.png" alt="" />
                            <img v-else-if="device.status == 'Ups!'" src="@/assets/images/icons/ups.png" alt="" />
                            <span>{{ device.status }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" class="status-side-2 more-blast" >
                        <div class="icon-vent-2 mr-0 more-button2">
                          <b-button class="vent-btn mr-0" variant="none" @click="openDeviceDetails(device.id)">
                            <span>{{ $t("More") }}</span>
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="pb-1" v-else>
            <b-row class="match-height">
              <b-col md="12" lg="12">
                <b-card no-body class="card-mobile">
                  <b-card-body>
                    <div
                      class="
                                                                                                                                                home-top
                                                                                                                                                d-flex
                                                                                                                                                flex-column flex-lg-row
                                                                                                                                                align-items-lg-center
                                                                                                                                                justify-content-lg-between
                                                                                                                                              ">
                      <b-card-title class="home-title mb-0">{{ device.name }}
                      </b-card-title>
                      <div
                        class="
                                                                                                                                                  device-info-wrapper
                                                                                                                                                  relative
                                                                                                                                                  d-flex
                                                                                                                                                  align-items-center
                                                                                                                                                  justify-content-lg-end
                                                                                                                                                ">
                        <p class="serial-number">
                          {{ $t("Serial") }}:
                          <span>{{ device.serial_number }}</span>
                        </p>
                        <p class="model-number">
                          {{ $t("Model") }}:
                          <span>{{ device.model_code }}</span>
                        </p>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="align-items-center">
              <b-col md="12" lg="4">
                <b-card no-body class="card-mobile">
                  <b-card-body>
                    <div class="home-img home-1">
                      <img :src="`${imageS3}${device.image}`" alt="" width="525" height="251" />
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="12" lg="8">
                <b-card no-body class="home-status">
                  <b-card-body>
                    <b-row>
                      <b-col class="status-side" xl="4">
                        <b-card-title class="text-status mb-0">{{ $t("Temperature") }}
                        </b-card-title>
                        <div class="alert-temps icon-status">
                          <div class="alert-temp">
                            <!-- <vue-apex-charts type="radialBar" height="300" :options="goalOverviewRadialBar.chartOptions"
                              :series="[device.percent]" /> -->
                            <div class="temp-range">
                              <!-- <span>
                                {{ device.current_metrics.min_internal_temp }}{{ device.current_metrics.show_temps ? "°C"
                                  :
                                  "" }}
                              </span> -->
                              <span></span>
                              <img src="@/assets/images/icons/thermometer.png" alt="" style="max-width: 64px; max-height: 64px;"/>
                              <span style="font-size: 35px;">
                                {{
                                  device.current_metrics.current_internal_temp?
                                  device.current_metrics.current_internal_temp.toLocaleString(
                                    "default",
                                    {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    }
                                  ):""
                                }}°C</span>
                                <span></span>
                              <!-- <span>
                                {{ device.current_metrics.max_internal_temp }}{{ device.current_metrics.show_temps ? "°C"
                                  :
                                  "" }}
                              </span> -->
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col class="status-side energy-consumption" lg="4">
                        <b-card-title class="text-status mb-0">{{ $t("Consumption") }}
                        </b-card-title>
                        <div class="status-icons">
                          <span :class="
                            comparisonClassConsumption(
                              device.current_metrics
                                .power_consumption_24h_comparison
                            )
                          ">{{
                            device.current_metrics.power_consumption_24h_comparison?
                                device.current_metrics
                                  .power_consumption_24h_comparison >= 0
                                ? `+${device.current_metrics.power_consumption_24h_comparison.toLocaleString(
                                  "default",
                                  {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                  }
                                )}`
                                : `${device.current_metrics.power_consumption_24h_comparison.toLocaleString(
                                  "default",
                                  {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                  }
                                )}`
                                :""
                              }}
                            kWh</span>
                          <div class="icon-status energy-icon">
                            <span>{{
                              device.current_metrics.power_consumption_24h ?
                              device.current_metrics.power_consumption_24h.toLocaleString(
                                "default",
                                {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                }
                              ):""
                            }}
                              kWh</span>
                            <img v-if="
                              device.current_metrics
                                .power_consumption_24h_comparison > 0
                            " src="@/assets/images/icons/consumo-red.png" alt="" />
                            <img v-else src="@/assets/images/icons/consumo-green.png" alt="" />
                          </div>
                          <div class="info-btn-home">
                            <b-button class="btn-sm temp-info-btn-home" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-tooltip.hover.left="
                                `${$t(
                                  'Info from the past 24h and compared with the previous 24. Last update:'
                                )} ${new Date(
                                  `${device.last_update} UTC`
                                ).toLocaleString()}`
                              " variant="none">
                              <feather-icon class="temp-info-icon-home" icon="AlertCircleIcon" />
                            </b-button>
                          </div>
                        </div>
                        <div class="mb-2">
                          <b-card-title class="text-status mb-0">{{ $t("Active Alerts") }}
                          </b-card-title>
                          <div class="home-alert">
                            <div style="position: relative">
                              <feather-icon class="alert-icon" icon="BellIcon" />
                              <span class="number-small">{{ device.active_alerts_counter }}</span>
                            </div>
                            <span>{{ $t(device.device_alert) }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="4" class="status-side-2">
                        <b-card-title class="text-status mb-0">{{ $t("Device Status") }}
                        </b-card-title>
                        <div class="status-icons">
                          <div class="icon-status">
                            <img v-if="device.status === 'OK!'" src="@/assets/images/icons/ok.png" alt="" />
                            <img v-else-if="device.status == 'NOK!'" src="@/assets/images/icons/nok.png" alt="" />
                            <img v-else-if="device.status == 'Ups!'" src="@/assets/images/icons/ups.png" alt="" />
                            <span>{{ device.status }}</span>
                          </div>
                        </div>
                        <div class="icon-vent-2 mr-0 more-button">
                          <b-button class="vent-btn mr-0" variant="none" @click="openDeviceDetails(device.id)">
                            <span>{{ $t("More") }}</span>
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
      </swiper>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import "swiper/css/swiper.css";
import { url } from "@/@core/utils/validations/validations";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    Swiper,
    SwiperSlide,
    VueApexCharts,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      defaultResponse: {
        min: 0,
        max: 0,
        current_temp: 0,
        consumption: 0,
        percent: 0,
      },
      isPauseSlider: false,
      swiperOptions: {
        sliderPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 50,
        speed: 3000,
        loop: false,
        preventClicks: false,
        preventClicksPropagation: false,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
      },
      goal_overview: {
        min_temp: "0,0°C",
        max_temp: "3,0°C",
      },
      goalOverviewRadialBar: {
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#fff"],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "50%",
              },
              track: {
                background: "rgba(255, 255, 255, 0.5)",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  show: false,
                  offsetY: 30,
                  color: "#fff",
                  fontSize: "18px",
                },
                value: {
                  show: false,
                  offsetY: -15,
                  color: "#fff",
                  fontSize: "29px",
                },
              },
            },
          },
          legend: { show: false },
          fill: {
            colors: "#fff",
            opacity: 1,
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: [""],
        },
      },
    };
  },
  methods: {
    comparisonClassConsumption(value) {
      return `consumo-text-${value <= 0 ? "green" : "red"}`;
    },
    stopStartSlider(action) {
      if (action == "stop") {
        this.swiper.autoplay.stop();
      } else {
        this.swiper.autoplay.start();
      }
    },
    openDeviceDetails(deviceData) {
      this.$router.push({ name: "device", params: { id: deviceData } });
    },
    isActive(value) {
      return value.last_update != null;
    },
    async getDevices() {
      let devices = [];
      const deviceEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`;

      let res = await this.$http.get(deviceEndpoint);

      if (res.status != 200) return [];

      if (res.data.length < 3) {
        devices = res.data;
      } else {
        devices = res.data.sort((a, b) => new Date(b.last_update).getTime() - new Date(a.last_update).getTime()); // Descending by last_update
        devices = devices.splice(0, 3);
      }

      devices = await Promise.all(
        devices.map(async (device) => {
          if (device.typology !== "Blast Chiller") {
            const currentMetrics = await this.getGenericDeviceCurrentMetrics(device.id);

            device.current_metrics = currentMetrics;

            this.getRecipeTemp(device);

            if (device.current_metrics) {
              device.percent = this.get_percent(
                currentMetrics.current_internal_temp,
                currentMetrics.min_internal_temp,
                currentMetrics.max_internal_temp
              );
            }

            return device;
          } else {
            const deviceStatusEndpoint = `${process.env.VUE_APP_API_URL}api/devices/${device.id}/blast-chiller-metric/`;
            const res = await this.$http.get(deviceStatusEndpoint)

            let { operation_status, operation_description } = res.data;
            operation_status = operation_status ? "On" : "Disconnected";
            operation_description = operation_description !== "-" ? operation_description : "No active cycle"
            return { ...device, operation_status, operation_description };
          }
        }));

      this.$store.commit("devices/SET_DEVICES_BANNER_LIST", devices);
    },
    get_percent(current, min, max) {
      let percent = 0
      let val1 = current - min;
      let val2 = max - min;
      if (val2 == 0)
        percent = (val1 * 100).toFixed(2);
      else
        percent = ((val1 / val2) * 100).toFixed(2);
      if (percent > 0 && percent < 100)
        return percent
      else if (percent <= 0)
        return -1
      else
        return 100
    },
    getRecipeTemp(device) {
      let last_updated_recipe = Number(
        device.current_metrics.current_recipe
      ).toFixed(0);
      if (last_updated_recipe === 200) last_updated_recipe = 1;
      let recipe_obj = this.recipes.find((x) => x.code == last_updated_recipe);
      device.current_metrics.min_internal_temp = recipe_obj
        ? recipe_obj.min_temp
        : "";
      device.current_metrics.max_internal_temp = recipe_obj
        ? recipe_obj.max_temp
        : "";
      device.current_metrics.show_temps = recipe_obj ? true : false;
    },
    async getGenericDeviceCurrentMetrics(deviceId) {
      const genericDeviceMetricsEndpoint = `${process.env.VUE_APP_API_URL}api/devices/${deviceId}/generic-metric/`;

      const res = await this.$http.get(genericDeviceMetricsEndpoint);

      if (res.data)
        return res.data;

      return {
        current_internal_temp: 0,
        current_recipe: "0",
        defrost_cycle_on: false,
        id: 0,
        illumination_on: false,
        keyboard_controller_blocked: false,
        last_update: "",
        max_internal_temp: 0,
        min_internal_temp: 0,
        power_consumption_24h: 0,
        power_consumption_24h_comparison: 0,
        turned_on: false
      };
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper_device.$swiper ? this.$refs.swiper_device.$swiper:"";
    },
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    devices() {
      return this.$store.state.devices.devicesListBanner;
    },
    recipes() {
      return this.$store.state.recipes.recipes;
    },
  },
  async created() {
    this.$store.dispatch("recipes/getRecipes", { context: this });
    await this.getDevices();
  },
};
</script>
<style scoped>
.more-button {
  margin-top: 50px !important;
}

.more-button2 {
  margin-top: 40% !important;
}

.more-blast{
  border-left: solid 1px #fff
}
@media (max-width: 500px) {
  .card-mobile{
    margin-bottom: 10px;
  }

  .more-button2{
    margin-top: 22% !important;
  }

  .more-blast{
    border-left: none;
  }

  .energy-consumption{
    margin-top:20px;
  }

  .energy-icon{
    padding-bottom: 20px;
  }

}

</style>
