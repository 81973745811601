var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBasic)+" ")]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Search')}})],1),_c('b-button',{staticClass:"search-btn",attrs:{"variant":"none"}},[_c('span',[_vm._v(_vm._s(_vm.$t("GO")))])])],1)])],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-baseline",attrs:{"cols":"12","md":"9"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Show")]),_c('v-select',{staticClass:"mx-",attrs:{"options":['3', '5', '10'],"searchable":false},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1)])],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.devicesWithAlert,"rtl":_vm.direction,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
      nextLabel: _vm.$t('Next'),
      prevLabel: _vm.$t('Prev'),
      rowsPerPageLabel: _vm.$t('Rows per page'),
      ofLabel: _vm.$t('of'),
    }},on:{"on-row-click":_vm.openDeviceDetails},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'typology')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(props.row.typology)))])]):(props.column.field === 'example2')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example2))])]):(props.column.field === 'example3')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example3))])]):(props.column.field === 'example5')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.example5)}},[_vm._v(" "+_vm._s(props.row.example5)+" ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }